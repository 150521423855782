import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Redirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate({
            pathname: '/',
            search: '?category=3',
        });
    }, [])
    return (
        <></>
    )
}

export default Redirect;