import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import { produce } from "immer";

import {
  bodyRequest,
  headers,
  quoteRequest,
  distributorQuick,
  distributorRequest,
} from "../../helpers/utils";
import DefaultImg from "../../assets/images/no_image.png";
import { endpoints } from "../../helpers/endpoints";

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./style.scss";
import Map from "../../components/Maps";
import ProductItems from "./components/ProductItems";
import BasicInfo from "./components/BasicInfo";
import SkeletonDetail from "./components/SkeletonDetail";
import SkeletonItems from "./components/SkeletonItems";
import CheckPrice from "./components/CheckPrice";

const ProductDetail = () => {
  const [service, setService] = useState();
  const [bookingQuotes, setBookingQuotes] = useState([]);
  const [detailShow, setDetailShow] = useState(false);
  const [productItemShow, setProductItemShow] = useState("none");
  const [skeletonItemShow, setSkeletonItemShow] = useState("none");
  const [onRequest, setOnRequest] = useState("true");
  const [quotesInfo, setQuotesInfo] = useState({});
  const [errorItems, setErrorItems] = useState(false);
  const [secondDist, setSecondDist] = useState();
  const [productId, setProductId] = useState();
  const [totalPrice, setTotalPrice] = useState([]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [language] = useOutletContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const today = new Date();
  const date = new Date(today);
  date.setDate(date.getDate() + 1);

  const detailRequest = bodyRequest;
  const quoteRequestData = quoteRequest;

  const resetPayload = () => {
    detailRequest.request.Availability = {
      MergeMethod: 1,
      Window: {
        Size: 42,
        StartDate: new Date(),
      },
    };

    detailRequest.request.Filter = {
      Type: "Service",
      TagCriteria: {},
      Bookability: {},
    };

    delete detailRequest.request.Paging.Token;
  };

  useEffect(() => {
    resetPayload();

    detailRequest.request.Filter.Ids = [id];

    if (id === "af64f8e3-5126-4a1c-9820-e1f66853fd64") {
      window.location.href =
        "https://localprime.reforsindo.com/product?id=359ec1ff-b86d-4108-acd9-b3245f168eb4&on_req=false&second_dist=true";
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    const langParams = searchParams.get("lang");
    detailRequest.request.Language = language === "en" ? "en-US" : "ja-JP";
    quoteRequestData.request.Language = language === "en" ? "en-US" : "ja-JP";
    if (langParams) {
      detailRequest.request.Language = language === "en" ? "en-US" : "ja-JP";
      quoteRequestData.request.Language = language === "en" ? "en-US" : "ja-JP";
    }

    setDetailShow(false);
    setProductItemShow("none");

    detailRequest.request.Output.Availability.NumberOfDays = 31;

    detailRequest.request.Output.Children = {
      Output: {
        CommonContent: {
          All: true,
        },
        Features: true,
        Rating: true,
        Reviews: {
          IncludeFullDescription: true,
          IncludeShortReview: true,
          MaxReturnCount: 10,
          MaxReturnCountSpecified: true,
        },
        Availability: {
          StartDate: new Date(),
          NumberOfDays: 4,
          MergeMethod: 2,
          FlagCampaign: true,
        },
      },
      Filter: {
        Ids: null,
        Type: 4,
      },
    };
    const onReq = searchParams.get("on_req");
    const second_dist = searchParams.get("second_dist");
    const product_id = searchParams.get("product_id");

    setProductId(product_id);

    setSecondDist(second_dist);
    if (onReq === "true") detailRequest.request.ShortName = distributorRequest;
    if (second_dist === "true")
      detailRequest.request.ShortName = "shinkibusco_2";
    else detailRequest.request.ShortName = distributorQuick;

    if (!langParams || language === langParams) {
      axios
        .post(endpoints.search, detailRequest, { headers: headers })
        .then((response) => {
          setService(response.data.Entities[0]);
          setDetailShow(true);
        });
    }
  }, [searchParams, location, language]);

  useEffect(() => {
    setBookingQuotes([]);
  }, []);

  useEffect(() => {
    const onReq = searchParams.get("on_req");
    setOnRequest(onReq);
    if (
      service &&
      service.IndustryCategoryGroups[0] === 3 &&
      onReq === "false"
    ) {
      getQuote();
    }
  }, [service]);

  useEffect(() => {
    setBookingQuotes(bookingQuotes.sort((a, b) => a.Name - b.Name));
  }, [bookingQuotes]);

  const getQuote = (values) => {
    setProductItemShow("none");

    quoteRequestData.request.Configurations[0].Pax.Adults =
      parseInt(values && values.pax) || 1;
    quoteRequestData.request.Configurations[0].Pax.Children =
      parseInt(values && values.children) || 0;
    quoteRequestData.request.Configurations[0].Pax.Seniors =
      parseInt(values && values.seniors) || 0;
    let dateValue = new Date();
    if (values?.date) {
      dateValue = new Date(values.date);

      const dateUtc = Date.UTC(
        dateValue.getUTCFullYear(),
        dateValue.getUTCMonth(),
        dateValue.getUTCDate(),
        dateValue.getUTCHours(),
        dateValue.getUTCMinutes(),
        dateValue.getUTCSeconds()
      );
      quoteRequestData.request.CommencementDate =
        values && new Date(dateUtc).toISOString();
    } else {
      quoteRequestData.request.CommencementDate = new Date();
    }

    quoteRequestData.request.Duration =
      parseInt(values && values.duration) || null;

    setBookingQuotes([]);

    if (service && service.Children.length > 0) {
      const onReq = searchParams.get("on_req");
      if (onReq === "true")
        quoteRequestData.request.Shortname = distributorRequest;
      if (secondDist === "true")
        quoteRequestData.request.Shortname = "shinkibusco_2";
      else quoteRequestData.request.Shortname = distributorQuick;

      if (productId) {
        let indexId = 0;
        let selectedId = {};
        service.Children.forEach((children, i) => {
          if (children.Code === productId) {
            indexId = i;
            selectedId = children;

            dispatchQuoteBooking(selectedId, indexId);
          }
        });
      } else {
        service.Children.map((children, i) => {
          dispatchQuoteBooking(children, i);
        });
      }
    }
  };

  const dispatchQuoteBooking = (children, i) => {
    quoteRequestData.request.IndustryCategoryGroup =
      children.IndustryCategoryGroups[0];
    quoteRequestData.request.IndustryCategory = children.IndustryCategory;
    quoteRequestData.request.Configurations[0].ProductId = children.Id;
    setSkeletonItemShow("block");

    axios
      .post(endpoints.bookingQuote, quoteRequestData, { headers: headers })
      .then((response) => {
        const mergeData = { ...service.Children[i], ...response.data };
        mergeData.id = response.data.Configurations[0].ProductId;
        mergeData.quantity = 1;
        if (secondDist === "true") mergeData.secondDist = true;
        mergeData.price = response.data.Configurations[0].Quotes
          ? response.data.Configurations[0].Quotes[0].TotalPrice
          : null;
        setBookingQuotes((data) => [...data, mergeData]);

        setProductItemShow("block");
        setSkeletonItemShow("none");
      })
      .catch((error) => {
        console.log(error);
        setErrorItems(true);
        setProductItemShow("block");
        setSkeletonItemShow("none");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let values = null;
    if (service.IndustryCategoryGroups[0] !== 3) {
      values = {
        date: e.target[0] ? e.target[0].value : "",
        duration:
          service.IndustryCategoryGroups[0] === 0 ? e.target[1].value : null,
        pax: 1,
        children: service.Settings.PresentChildren ? 0 : "",
        seniors: service.Settings.PresentSeniors ? 0 : "",
      };
      setQuotesInfo(values);
    }

    getQuote(values);
  };

  const changePax = (count, type) => {
    let values = {
      date: quotesInfo.date || new Date(),
      duration: quotesInfo.duration,
      pax: quotesInfo.pax || 1,
      children: quotesInfo.children,
      seniors: quotesInfo.seniors,
    };

    if (type === "pax") {
      values.pax = count;
    } else if (type === "children") {
      values.children = count;
    } else {
      values.seniors = count;
    }

    setQuotesInfo(values);
    getQuote(values);
  };

  const changeQuantity = (value, id) => {
    bookingQuotes.map((item) => {
      if (item.id === id) {
        item.quantity = value;
        const price = value * item.Configurations[0].Quotes[0].TotalPrice;
        if (!isEmpty(totalPrice)) {
          totalPrice.map((item) => {
            if (item.id === id) {
              setTotalPrice(
                produce((draft) => {
                  const dataPrice = draft.find((item) => item.id === id);
                  dataPrice.totalPrice = price;
                })
              );
            } else {
              setTotalPrice((prev) => [...prev, { id: id, totalPrice: price }]);
            }
          });
        } else {
          setTotalPrice((prev) => [...prev, { id: id, totalPrice: price }]);
        }
      }
    });
  };

  const settings = {
    dots: true,
    adaptiveHeight: true,
  };

  const getServiceType = () => {
    let serviceType = t("accommodation");
    if (service && service.IndustryCategoryGroups) {
      switch (service.IndustryCategoryGroups[0]) {
        case 0:
          serviceType = t("accommodation");
          break;
        case 1:
          serviceType = t("activity");
          break;
        case 2:
          serviceType = t("restaurant");
          break;
        case 3:
          serviceType = t("produce");
          break;
        default:
          return t("accommodation");
      }
    }

    return serviceType;
  };

  const calendarUpdate = (date) => {
    detailRequest.request.Output.Availability.StartDate = date;

    axios
      .post(endpoints.search, detailRequest, { headers: headers })
      .then((response) => {
        setService(response.data.Entities[0]);
        setDetailShow(true);
      });
  };

  return (
    <>
      <div className="container">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="back"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
          {t("go_back")}
        </a>
        <Row className="product">
          <div
            className="skeletonWrapper"
            style={{ display: !detailShow ? "block" : "none" }}
          >
            <SkeletonDetail />
          </div>
          {service && (
            <Col
              xs={12}
              className="productWrapper"
              style={{ display: detailShow ? "block" : "none" }}
            >
              <div className="serviceType">{getServiceType()}</div>
              <h2 className="title mb-5">{service.Name}</h2>
              <div className="carousel">
                {service.Images !== null ? (
                  <Slider {...settings}>
                    {service.Images.map((service, i) => {
                      return (
                        <div key={i}>
                          <img className="image" src={service.Url} />
                        </div>
                      );
                    })}
                  </Slider>
                ) : (
                  <img src={DefaultImg} />
                )}
              </div>
              <div
                className="description mb-3"
                dangerouslySetInnerHTML={{ __html: service.LongDescription }}
              ></div>
              <CheckPrice
                date={date}
                service={service}
                handleSubmit={handleSubmit}
                calendarUpdate={calendarUpdate}
              />
              <div
                className="availableProducts mb-4"
                style={{ display: productItemShow }}
              >
                <div className="sectionTitle">
                  <span>
                    {service?.IndustryCategoryGroups &&
                    service.IndustryCategoryGroups[0] === 1
                      ? t("available_products_activity")
                      : service.IndustryCategoryGroups[0] === 3
                      ? t("available_products_goods")
                      : t("available_products")}
                  </span>
                </div>
                <ProductItems
                  bookingQuotes={bookingQuotes}
                  changeQuantity={changeQuantity}
                  onRequest={onRequest}
                  language={language}
                  service={service}
                  quotesInfo={quotesInfo}
                  error={errorItems}
                  totalPrice={totalPrice}
                  changePax={changePax}
                />
              </div>
              <SkeletonItems skeletonItemShow={skeletonItemShow} />
              <div className="info mb-4">
                <div className="sectionTitle">
                  <span>{t("basic_info")}</span>
                </div>
                <BasicInfo service={service} />
              </div>
              {service.Geocodes !== null && (
                <div className="map">
                  <div className="sectionTitle">
                    <span>{t("map")}</span>
                  </div>
                  <div className="mapContainer">
                    <Map positions={service} />
                  </div>
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default ProductDetail;
