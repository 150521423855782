import React, { useState, useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { i18n } from "./lang/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Footer from "./components/Footer";
import Navigation from "./components/Navigation";

import buttonTop from "./assets/images/btnTop.webp";

import "./App.scss";
import Cart from "./components/Cart";

function App() {
  const [language, setLanguage] = useState("jp");
  const [searchParams, setSearchParams] = useSearchParams();
  const langParams = searchParams.get("lang");

  useEffect(() => {
    i18n.changeLanguage("jp");
  }, []);

  useEffect(() => {
    if (langParams && langParams !== language) {
      setLanguage(langParams);
    }
  }, [langParams]);

  const handleOnclick = (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    searchParams.set("lang", e.target.value);
    setSearchParams(searchParams);
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="App">
      <Navigation />
      <div className="translate container">
        <div className="content">
          <FontAwesomeIcon icon={faLanguage} />
          <button
            className={`btn ${
              language === "en" || language === "en-US" ? "active" : ""
            }`}
            value="en"
            onClick={handleOnclick}
          >
            English
          </button>
          <button
            className={`btn ${
              language === "jp" || language === "ja-JP" ? "active" : ""
            }`}
            value="jp"
            onClick={handleOnclick}
          >
            日本語
          </button>
        </div>
      </div>
      <Outlet context={[language]} />
      <Cart language={language} />
      <div className="top" onClick={() => goToTop()}>
        <img src={buttonTop} />
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default App;
