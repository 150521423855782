import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_JP } from "./jp/translations";
import { TRANSLATIONS_EN } from "./en/translations";

i18n.use(initReactI18next).init({
  lng: "jp",
  fallbackLng: "jp",
  resources: {
    jp: {
      translation: TRANSLATIONS_JP,
    },
    en: {
      translation: TRANSLATIONS_EN,
    },
  },
});

export { i18n };
